import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <span style={{
      "fontFamily": "Raleway",
      "fontSize": "32px",
      "color": "4A5568",
      "fontWeight": "bold"
    }}>AdvisorFlow REST API Reference</span><br /><br />
    <p>{`Welcome to the reference documentation for the AdvisorFlow REST API.`}</p>
    <p>{`In this guide we'll outline the single resource available in this API, Clients, presenting its different endpoints with descriptions, parameters and code examples.`}</p>
    <p>{`Instructions for authorization can be found `}<a href="/authorization" style={{
        "color": "#2B6CB0"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "clients",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#clients",
        "aria-label": "clients permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Clients`}</h2>
    <p>{`The Clients API allows authenticated advisors to fetch their AdvisorFlow client list and full client data, pulling it seamlessly and securely into the other software they use.`}</p>
    <p>{`Client data — consisting of detailed personal and financial information about an advisor’s client collected through AdvisorFlow surveys — remains confidential at all times. When accessed by an advisor through the Clients API, this data becomes available for the advisor to analyze and work with in compatible software.`}</p>
    <p>{`This can be leveraged to automate what would otherwise be a lengthy process of data entry for advisors using multiple software applications to optimize and enhance their workflow.`}</p>
    <p>{`With the Clients API, you can:`}</p>
    <ul>
      <li parentName="ul">
        <a href="/clients/getclientlist" style={{
          "color": "#2B6CB0"
        }}>Get your client list</a>
      </li>
      <li parentName="ul">
        <a href="/clients/getclientdata" style={{
          "color": "#2B6CB0"
        }}>Get data about a client</a>
      </li>
      <li parentName="ul">
        <a href="/clients/updateclientdata" style={{
          "color": "#2B6CB0"
        }}>Update data for a client</a>
      </li>
      <li parentName="ul">
        <a href="/clients/deleteclient" style={{
          "color": "#2B6CB0"
        }}>Delete a client</a>
      </li>
    </ul>
    {
      /* PRIVATE: for AdvisorFlow team only, not for immediate development or release:
      ## Advisors
      An Advisors API could allow an authenticated user, with permission from an advisor, to access and modify that advisor’s client list and client data. (The user here could be an advisor’s assistant, for example.)
      Cross-account access would be made possible through this Advisors resource. We would require an {advisorId} path parameter in each request. Aside from the different paths, requests would be identical to those in the Clients API:
      Get an advisor's client list
      ```GET /advisors/{advisorId}/clients```
      Get data about an advisor’s client
      ```GET /advisors/{advisorId}/clients/{client_id}```
      Update data for an advisor’s client
      ```PUT /advisors/{advisorId}/clients/{client_id}```
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      